<template>
  <div class="preloader">
    <div class="preloader-background">
      <div class="preloader-icon">
        <object
          type="image/svg+xml"
          :data="require('@/assets/preloader.svg')"
        ><img src="@/assets/logo-denga.svg"></object>
      </div>
      <div class="preloader-text">
        Идет загрузка...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preloader'
}
</script>

<style scoped lang="scss">
   .preloader {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9999;

      &-background {
         background: rgba(189, 194, 200, 0.9);
         min-height: 100vh;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
      }

      &-icon {
         width: 150px;
      }
   }
</style>
