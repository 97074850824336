import Vue from 'vue'
import Vuex from 'vuex'
import enums from '@/helpers/enums'
import getMessage from '@/helpers/i18n/messages'
import cardErrorMessages from '@/helpers/i18n/cardErrorMessages'
// import { GET_LOAN_DATA } from '../helpers/services/urls'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    response: null,
    errors: [],
    paymentSystem: null,
    paymentCardFormUrl: null,
    cardFormResponseData: null,
    best2PayErrors: null,
    mandarinOperationId: null,
    preloader: false
  },
  getters: {
    isPaymentSystemBest2Pay: state => state.paymentSystem === enums.PAYMENT_SYSTEM.BEST2PAY,
    getCardFormResponseData: state => state.cardFormResponseData,
    getBest2PayErrors: state => getMessage(state.best2PayErrors, cardErrorMessages.best2PayErrors, true),
    isPaymentSystemAlfaBank: state => state.paymentSystem === enums.PAYMENT_SYSTEM.ALFABANK,
    isPaymentSystemHasCardFormUrl: state => state.paymentSystem !== enums.PAYMENT_SYSTEM.MANDARIN

  },
  mutations: {
    RESPONSE: (state, payload) => {
      state.response = payload
    },
    ERRORS: (state, payload) => {
      state.errors = payload
    },
    CLEAR_ERRORS: (state) => {
      state.errors = []
    },
    SET_PAYMENT_CARD_FORM_RESPONSE_DATA: (state, data) => {
      state.cardFormResponseData = data
    },
    SET_PAYMENT_CARD_FORM: (state, data) => {
      state.paymentCardFormUrl = data
    },
    SET_PAYMENT_SYSTEM: (state, data) => {
      state.paymentSystem = data
    },
    SET_BEST2PAY_ERRORS: (state, data) => {
      state.best2PayErrors = data
    },
    SET_MANDARIN_OPERATION_ID: (state, data) => {
      state.mandarinOperationId = data
    },
    PRELOADER: (state, data) => {
      state.preloader = data || false
    }
  },
  actions: {
    async API ({ commit }, { method, url, body = null, headers = null, preloader = false }) {
      try {
        if (preloader) {
          commit('PRELOADER', preloader)
        }

        return await Vue.prototype.$http[method](url, body, headers)
      } catch (e) {
        if (!e.response) return

        const data = e.response.data
        if (data && data.hasOwnProperty('server_error')) {
          commit('ERRORS', data && data.server_error)
        } else {
          commit('ERRORS', data)
        }
      } finally {
        if (preloader) {
          commit('PRELOADER')
        }
      }
    },
    async BORROWER_PAY_DATA ({ dispatch }, body) {
      return dispatch('API', {
        method: 'get',
        url: `borrower_pay_data/${body.hash || null}`
      })
    },
    async SET_EMAIL ({ dispatch }, body) {
      return dispatch('API', {
        method: 'post',
        url: `set_email/${body.hash}`,
        body: {
          email: body.email
        }
      })
    },
    async CREATE_PAY ({ dispatch, commit, getters }, body) {
      const { data } = await dispatch('API', {
        method: 'post',
        url: `create_pay/${body.hash}`,
        body: body,
        preloader: true
      })

      commit('SET_PAYMENT_SYSTEM', data.payment_system)

      return data
    },
    SET_PAYMENT_CARD_FORM_RESPONSE_DATA: async function ({ state, commit, dispatch }, { data, body }) {
      commit('SET_PAYMENT_CARD_FORM_RESPONSE_DATA', data)

      if (data?.code === 'null') {
        data.code = null
      }

      if (data && (data.code || data.error || data.isError)) {
        commit('SET_BEST2PAY_ERRORS', data.code || data.error || 'default')
        await dispatch('CREATE_PAY', body)
      }

      if (!data) {
        commit('SET_BEST2PAY_ERRORS', null)
      }
    },
    SET_BEST2PAY_CLEAN_ERRORS ({ commit }) {
      commit('SET_BEST2PAY_ERRORS', null)
    }
  },
  modules: {}
})
